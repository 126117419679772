<template>
  <div class="studentStudyDetails">
    <div class="cont">
      <el-tabs>
        <el-tab-pane v-for="(item, index) in disputeData" :key="index" :label="item.stationName || '/'">
          <el-descriptions :column="1">
            <el-descriptions-item label="助学账号">{{item.loginNum}}</el-descriptions-item>
            <el-descriptions-item label="账号状态">{{item.accountStatusName}}</el-descriptions-item>
            <!-- <el-descriptions-item label="班级">{{item.}}</el-descriptions-item> -->
            <el-descriptions-item label="学校">{{item.schoolName}}</el-descriptions-item>
            <el-descriptions-item label="姓名">{{item.name}}</el-descriptions-item>
            <el-descriptions-item label="性别">{{item.sex == 1 ? '女' : '男'}}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{item.cardId}}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{item.phone}}</el-descriptions-item>
            <el-descriptions-item label="招生教学点">{{item.stationName || '暂无'}}</el-descriptions-item>
            <el-descriptions-item label="招生批次">{{item.admissionBatch}}</el-descriptions-item>
            <el-descriptions-item label="招生层次">{{item.eduCategoryName}}</el-descriptions-item>
            <el-descriptions-item label="招生专业">{{item.majorName || '暂无'}}</el-descriptions-item>
            <el-descriptions-item label="来源途径">{{item.source || '暂无'}}</el-descriptions-item>
            <el-descriptions-item label="备注">{{item.remarks || '暂无'}}</el-descriptions-item>
          </el-descriptions>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'studentStudyDetails',
  data() {
    return {
      activeName: null,
      parameter: {
        tenantId: null,
        aidStudentId: null
      },
      disputeData: [],
    }
  },
  created() {
    let query = this.$route.query;
    this.parameter.tenantId = query.tenantId;
    this.parameter.aidStudentId = query.aidStudentId;
    this.getDisputeList();
  },
  methods: {
    getDisputeList() {
      this.$request.disputeList(this.parameter).then(res => {
        if(res.data.code == 0) {
          this.disputeData = res.data.data;
          // this.activeName = this.disputeData[0].stationName
        }
      })
    }
  },
}
</script>

<style lang='less'>
  .studentStudyDetails {
    height: 100%;
    .cont {
      padding: 28px 40px;
      background-color: #fff;
    }
  }
</style>